<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Sales") }}</label>
      <span>/</span>
      <label>
        <router-link :to="{ name: 'subscribe' }">{{
          $t("Subscribes")
        }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("SubscribeDetails") }}</label>
    </div>

    <v-container fluid>
      <h2>{{$t('SubscribeDetails')}}</h2>
      <v-row class="mt-0">
        <v-col cols="4">
          <div class="customCard card1" style="background:##e0e5ef;color:#000;">
            <span class="d-block px-4 pt-4 font-big-bold"># <span>783</span></span>
            <span class="d-block px-4 pt-4 font-big-bold">اسم المشترك: <span>عبدالرحمن ظافر الشهري</span></span>
            <span class="d-block px-4 pt-4 font-big-bold">الجوال: <span>0552149212</span></span>
            <span class="d-block px-4 pt-4 font-big-bold">تاريخ الميلاد: <span>20/09/1993</span></span>
            <span class="d-block px-4 pt-4 font-big-bold">البريد الإلكتروني: <span>aa@a.com</span></span>
          </div>
        </v-col>
        <v-col cols="4"> 
            <div class="customCard">
            <p>تاريخ بداية الاشتراك: <span>05/01/2023</span></p>
          </div>
            <div class="customCard mt-5">
            <p>تاريخ نهاية الاشتراك: <span>05/05/2023</span></p>
          </div>
           <div class="customCard mt-5" style="background:#2196f3;color:#fff;">
            <p>عدد أيام الاشتراك: <span>9</span></p>
          </div>
            <div class="customCard mt-5" style="background:#ff9800;color:#fff;">
            <p>الأيام المتبقية: <span>4</span></p>
          </div>
           </v-col>
        <v-col cols="4"> 
           <div class="customCard">
            <p>الباقة: <span>غداء عمل</span></p>
          </div>
            <div class="customCard mt-5">
            <p>السعر: <span>715</span></p>
          </div>
            <div class="customCard mt-5">
            <p>نوع الاشتراك: <span>مدفوع</span></p>
          </div>
           <div class="customCard mt-5">
            <p>تجديد الاشتراك: <span>لا</span></p>
          </div>
           </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p style="text-align:center;">
            <span class="ml-3" style="display:inline-block;font-weight:bold;color:#000;font-size:18px;">أيام الاشتراك: </span>
            <span>السبت 

              <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px;color:red !important;"
              dense
              >mdi-close-circle-outline</v-icon>


            </span>
            <span class="mx-2">الأحد   
              <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px;color:green !important;"
              dense
              >mdi-checkbox-marked-circle-outline</v-icon>
              </span>
            <span class="mx-2">الاثنين 
                <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px;color:green !important;"
              dense
              >mdi-checkbox-marked-circle-outline</v-icon>
            </span>
            <span class="mx-2">الثلاثاء 
                <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px;color:red !important;"
              dense
              >mdi-close-circle-outline</v-icon>
            </span>
            <span class="mx-2">الأربعاء 
                <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px;color:green !important;"
              dense
              >mdi-checkbox-marked-circle-outline</v-icon>
            </span>
            <span class="mx-2">الخميس 
                <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px;color:red !important;"
              dense
              >mdi-close-circle-outline</v-icon>
            </span>
            <span class="mx-2">الجمعة 
                <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px;color:red !important;"
              dense
              >mdi-close-circle-outline</v-icon>
            </span>
          </p>
        </v-col>
      </v-row>

      <table class="subscribe_details_table">
      <tr>
        <th rowspan="2" style="background:#edfde4 !important;color:#000;font-size:14px;">الرغبات الشخصية</th>
        <th>المكونات الأساسية</th>
        <th>المكونات غير الأساسية</th>
        <th>المميزات</th>
        <th>الكربوهيدرات</th>
        <th>البروتين</th>
        <th>ملاحظات</th>
      </tr>
      <tr>
        <td></td>
        <!-- <td>لحم، فيليه، جمبري، دجاج</td> -->
        <td>لحم، هامور، سمك، سلمون</td>
        <td>شوربة جولاش اللحم، شوربة حريرة اللحم، شوربة حريرة</td>
        <td>150</td>
        <td>120</td>
        <td>(اضافة : سناك) (بدون :بليله , عدس, لحم ، سالمون ، هامور )</td>
      </tr>
      </table>

      <table class="subscribe_details_table subscribe_details_table2 mt-5">
      <tr>
        <th rowspan="2" style="background:#edfde4 !important;color:#000;font-size:14px;">بيانات التواصل</th>
        <th>المدينة</th>
        <th>الحي</th>
        <th>فترة</th>
        <th>رقم المنزل</th>
        <th>الشركة</th>
        <th>مجموعة الشخص</th>
        <th>العنوان</th>
        <th>ملاحظات</th>
      </tr>
      <tr>
        <td>وسط أ</td>
        <td>السليمانية</td>
        <td>صباحي</td>
        <td>1</td>
        <td></td>
        <td></td>
        <td></td>
        <td>اتصال يد بيد</td>
      </tr>
      </table>

    </v-container>
  </div>
</template>

<script>
export default {
  name: "subscribeDetails",
  data() {
    return {
      id: this.$route.params.id || null,
      details: null,
    };
  },
  methods: {
    
  },
  created() {
    if (this.$route.params.id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$store
        .dispatch(`subscribe/findData`, this.$route.params.id)
        .then((res) => {
          this.details= res.data;
            loading.close();
        });
    }

  },
};
</script>


<style>


.subscribe_details_table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.subscribe_details_table td, .subscribe_details_table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}


.subscribe_details_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #8f9e06 !important;
  color: white;
}
.subscribe_details_table2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #0a9ca1 !important;
  color: white;
}

.customCard {
  width: 100%;
  height: 60px;
  background: #edfde4;
  border-radius: 4px;
  color: #222;
  font-weight: bold;
  border: 1px solid #ccc;
}
.card1 {
  height: auto;
}
.font-big-bold {
  font-weight: bold;
  font-size: 16px;
  padding: 20px 15px;
}
.customCard p {
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
}
.val {
  color: blue;
}
.emptyVal {
  color: red;
}
</style>